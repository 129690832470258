import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx100WeekMaChartTemplate from '@/templates/crypto/xxx-100-week-moving-average-chart';

export default function Ethereum100WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx100WeekMaChartTemplate
      location={location}
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      articleId={ArticleList.ETHEREUM_100_WEEK_MOVING_AVERAGE}
    />
  );
}
